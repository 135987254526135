/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/no-explicit-any */
import api from '~/services/api';
import { formatCurrency } from '~/utils/formatting';
import {
  normalizeCurrency,
  normalizeCpf,
  normalizeNumber,
  normalizeDate,
  productUrl,
} from '~/utils/normalize';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import TagManager from 'react-gtm-module';
import { useHistory } from 'react-router-dom';
import { useMercadopago } from 'react-sdk-mercadopago';

import { ICard } from '../interface/Card';
import { IFormPayment } from '../interface/FormPayment';
import { IHeaders } from '../interface/Headers';
import { IOrder } from '../interface/Order';
import { IPayment } from '../interface/Payment';
import { IPaymentCredit } from '../interface/PaymentCredit';
import { LoadingScreen } from './LoadingScreen';
import { Badge, Col, Form, Modal, Row } from 'react-bootstrap';

interface IProps {
  order: IOrder;
  headersTokens: IHeaders;
  setTotal: (v: number) => void;
}

interface ICardItem {
  id?: number;
  hold_name?: string;
  hold_document?: string;
  expire_month?: string;
  expire_year?: string;
  cvv: string;
  number?: string;
  value: number;
  installments?: number;
  customInstallments?: any[];
  is_imperio_card: boolean;
}

const Payment: React.FC<IProps> = ({ order, headersTokens, setTotal }) => {
  const reRef = useRef<any>(null);
  const formCardCredit = useRef<any>(null);
  const [formPayment, setFormPayment] = useState<IFormPayment>({
    type: '',
    installments: [{ installment: 0, value: order.total, has_fee: false }],
  });
  const [formSelect, setFormSelect] = useState<string>('');
  const [formNewCard, setFormNewCard] = useState<boolean>(false);
  const [cards, setCards] = useState<ICard[]>([]);
  const [card, setCard] = useState<ICard>({
    brand: '',
    card: '',
    id: 0,
    is_imperio_card: false,
  });
  const [installment, setInstallment] = useState<number>(0);
  const [creditCard, setCreditCard] = useState<IPaymentCredit>({
    type: 'credit_card',
  });
  const [debitCard, setDebitCard] = useState<IPayment>({
    type: 'debit_card',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [birthDateModal, setBirthDateModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [birthdate, setBirthdate] = useState<string>('');

  const [twoCards, setTwoCards] = useState({
    card1: {
      cvv: '',
      value: 0,
      is_imperio_card: false,
    } as ICardItem,
    card2: {
      cvv: '',
      value: 0,
      is_imperio_card: false,
    } as ICardItem,
  });
  const [loadingInstallments, setLoadingInstallments] = useState(false);

  const hasLiveloPoints =
    order?.orderProducts &&
    order?.orderProducts?.findIndex(o => o.livelo_points > 0) > -1;

  const liveloTotalPointsBadge = (
    <div className="d-flex align-items-center">
      <img
        src="https://www.lojasimperio.com.br/images/livelo.png"
        alt="Livelo"
        style={{ maxWidth: '60px' }}
        className="w-100"
      />
      <div className="ml-2">
        <strong>Total de pontos a ganhar</strong>
        <h4 className="m-0">
          <strong>
            {order.orderProducts?.reduce((acc, item) => {
              if (formPayment.type === 'credit_card') {
                acc += Math.floor(
                  (item.livelo_points *
                    (order.code_coupon || installment > 1
                      ? item.price
                      : item.product.price_discount ||
                        item.product.price_in_full ||
                        item.price) *
                    item.qtd) /
                    100,
                );
              } else {
                acc += Math.floor(
                  (item.livelo_points *
                    (order.code_coupon ||
                    !['pix', 'boleto'].includes(formPayment.type)
                      ? item.price
                      : item.product.price_discount ||
                        item.product.price_in_full ||
                        item.price) *
                    item.qtd) /
                    100,
                );
              }

              return acc;
            }, 0)}
          </strong>
        </h4>
      </div>
    </div>
  );

  const yearsOptions = useMemo(() => {
    const y = new Date().getFullYear();

    const arr = [];

    for (let i = y; i < y + 15; i += 1) {
      arr.push(i);
    }

    return (
      <>
        {arr.map(a => (
          <option key={a} value={a}>
            {a}
          </option>
        ))}
      </>
    );
  }, []);

  async function handleInstallmentTwoCards() {
    setLoadingInstallments(true);
    try {
      const { data: installments1 } = await api.post(
        `/orders/installments/calculate/${order.id}`,
        {
          value_to_pay: twoCards.card1.value,
          type_card:
            twoCards.card1?.is_imperio_card ||
            normalizeNumber(twoCards?.card1?.number || '')?.slice(0, 6) ===
              '404002'
              ? 'imperio'
              : 'default',
        },
      );
      const { data: installments2 } = await api.post(
        `/orders/installments/calculate/${order.id}`,
        {
          value_to_pay: twoCards.card2.value,
          type_card:
            twoCards.card2?.is_imperio_card ||
            normalizeNumber(twoCards?.card2?.number || '')?.slice(0, 6) ===
              '404002'
              ? 'imperio'
              : 'default',
        },
      );
      setTwoCards({
        card1: {
          ...twoCards.card1,
          customInstallments: installments1,
          installments: undefined,
        },
        card2: {
          ...twoCards.card2,
          customInstallments: installments2,
          installments: undefined,
        },
      });
    } catch (err) {
      setTwoCards({
        card1: {
          ...twoCards.card1,
          customInstallments: undefined,
          installments: undefined,
        },
        card2: {
          ...twoCards.card2,
          customInstallments: undefined,
          installments: undefined,
        },
      });
    } finally {
      setLoadingInstallments(false);
    }
  }

  useEffect(() => {
    setTwoCards({
      card1: {
        ...twoCards.card1,
        customInstallments: undefined,
        installments: undefined,
      },
      card2: {
        ...twoCards.card2,
        customInstallments: undefined,
        installments: undefined,
      },
    });
  }, [twoCards.card1.value, twoCards.card2.value]);

  const sessionHiddenInput = document.getElementById(
    'SessionIDHidden',
  ) as HTMLInputElement;

  const sessionId = sessionHiddenInput?.value;

  const totalDiscount = useMemo(() => {
    let subtotal: number | undefined;
    // if (order.orderDeliveries) {

    order?.orderDeliveries?.forEach(orderDelivery => {
      if (!subtotal) subtotal = 0;
      subtotal += orderDelivery.orderProducts?.reduce((acc, cur) => {
        if (
          (cur.product.price_discount || cur.product.price_in_full) &&
          !order.code_coupon
        ) {
          return (
            acc +
            cur.qtd * (cur.product.price_in_full || cur.product.price_discount)
          );
        }
        return acc + cur.qtd * cur.product.price_end;
      }, 0);
    });

    return (
      order.service -
      order.discount +
      order.frete +
      (subtotal || order.subtotal)
    );
  }, [order]);

  useEffect(() => {
    async function loadCards() {
      const { data: dataCards } = await api.get(
        `customer/me/cards`,
        headersTokens,
      );
      setCards(dataCards);
    }
    if (!order.mask) {
      loadCards();
    }
  }, [order, headersTokens]);

  const mercadopago = useMercadopago.v1(
    'TEST-1c899401-aa6c-4261-86ef-75d514e917fb',
  );

  const history = useHistory();

  function switchFormPayment(type: string) {
    switch (type) {
      case 'pix':
        return 'PIX';
      case 'boleto':
        return 'Boleto';
      case 'two_cards':
        return '2 Cartões';
      case 'credit_card':
        return 'Cartão de crédito';
      case 'debit_card':
        return 'Cartão de débito';
      default:
        return '-';
    }
  }

  function switchFormPaymentIcon(type: string) {
    switch (type) {
      case 'pix':
        return 'fa-qrcode';
      case 'boleto':
        return 'fa-barcode';
      case 'two_cards':
        return 'fa-credit-card';
      case 'credit_card':
        return 'fa-credit-card';
      case 'debit_card':
        return 'fa-credit-card';
      default:
        return '';
    }
  }

  const imperioCardInstallments = useMemo(() => {
    const imperioCard = order.formPayments?.find(
      f => f.type === 'imperio_card',
    );

    if (imperioCard) {
      return imperioCard.installments;
    }

    return [];
  }, [order.formPayments]);

  const renderTwoCards = (index: 1 | 2) => {
    const item = index === 1 ? twoCards.card1 : twoCards.card2;

    const feeValue =
      item.customInstallments && item.installments
        ? (
            item.customInstallments.find(
              i => Number(i.installment) === Number(item.installments),
            ) || { fee_value: 0 }
          ).fee_value
        : 0;

    return (
      <div
        className="p-3 bg-white"
        style={{ marginBottom: '1.5rem', borderRadius: '0.4rem' }}
      >
        <div className="col-md-12">
          <h6 className="mt-2">
            <i className="fas fa-credit-card" /> Cartão {index}
          </h6>
        </div>
        <div className="form-group mb-2 col-md-12">
          <p className="form-label m-0">Número do cartão *</p>
          <input
            name="number"
            placeholder="0000 0000 0000 0000"
            className="form-control"
            autoComplete="off"
            onChange={e =>
              setTwoCards({
                ...twoCards,
                [`card${index}`]: {
                  ...(index === 1 ? twoCards.card1 : twoCards.card2),
                  number: e.target.value,
                },
              })
            }
          />
        </div>
        <div className="form-group mb-2 col-md-12">
          <p className="form-label m-0">Data de vencimento *</p>
          <div className="d-flex">
            <select
              name="month"
              autoComplete="off"
              onChange={e =>
                setTwoCards({
                  ...twoCards,
                  [`card${index}`]: {
                    ...(index === 1 ? twoCards.card1 : twoCards.card2),
                    expire_month: e.target.value,
                  },
                })
              }
              className="mr-1 form-control"
            >
              <option value="">MM</option>
              <option value="01">01</option>
              <option value="02">02</option>
              <option value="03">03</option>
              <option value="04">04</option>
              <option value="05">05</option>
              <option value="06">06</option>
              <option value="07">07</option>
              <option value="08">08</option>
              <option value="09">09</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
            </select>
            <select
              name="year"
              onChange={e =>
                setTwoCards({
                  ...twoCards,
                  [`card${index}`]: {
                    ...(index === 1 ? twoCards.card1 : twoCards.card2),
                    expire_year: e.target.value,
                  },
                })
              }
              className="ml-1 form-control"
            >
              <option value="">AAAA</option>
              {yearsOptions}
            </select>
          </div>
        </div>
        <div className="form-group mb-2 col-md-12">
          <p className="form-label m-0">Nome do titular *</p>
          <input
            name="cardHolder"
            placeholder="Digite nome do titular"
            autoComplete="off"
            className="form-control"
            onChange={e =>
              setTwoCards({
                ...twoCards,
                [`card${index}`]: {
                  ...(index === 1 ? twoCards.card1 : twoCards.card2),
                  hold_name: e.target.value,
                },
              })
            }
          />
          <small className="d-block" style={{ fontSize: '12px' }}>
            Digite exatamente como está no seu cartão
          </small>
        </div>
        <div className="form-group mb-2 col-md-12">
          <p className="form-label m-0">CPF do titular do cartão *</p>
          <input
            name="cardHolderDocument"
            placeholder="Digite cpf do titular do cartão"
            autoComplete="off"
            className="form-control"
            value={item.hold_document}
            onChange={e =>
              setTwoCards({
                ...twoCards,
                [`card${index}`]: {
                  ...(index === 1 ? twoCards.card1 : twoCards.card2),
                  hold_document: normalizeCpf(e.target.value),
                },
              })
            }
          />
        </div>
        <div className="form-group mb-2 col-md-12">
          <p className="form-label m-0">Valor a Pagar *</p>
          <input
            name="cardValue"
            className="form-control"
            autoComplete="off"
            value={normalizeCurrency(
              String(index === 1 ? twoCards.card1.value : twoCards.card2.value),
            )}
            onChange={e => {
              let inputValue = Number(normalizeNumber(e.target.value));

              if (inputValue > order.total) {
                inputValue = order.total;
              }

              setTwoCards({
                ...twoCards,
                [`card${index}`]: {
                  ...(index === 1 ? twoCards.card1 : twoCards.card2),
                  value: inputValue,
                },
                [`card${index === 1 ? 2 : 1}`]: {
                  ...(index === 1 ? twoCards.card2 : twoCards.card1),
                  value: order.total - inputValue,
                },
              });
            }}
            onBlur={handleInstallmentTwoCards}
            disabled={index === 2}
          />
        </div>
        {feeValue > 0 ? (
          <div>
            <span className="d-block">
              <i>+{formatCurrency(String(feeValue))} de juros</i>
            </span>
            <strong>
              Total: {formatCurrency(String(item.value + feeValue))}
            </strong>
          </div>
        ) : null}
        <div className="form-group mb-2 col-md-6">
          <p className="form-label m-0">CVV *</p>
          <input
            name="cvv"
            placeholder="XXX"
            autoComplete="off"
            className="form-control"
            onChange={e =>
              setTwoCards({
                ...twoCards,
                [`card${index}`]: {
                  ...(index === 1 ? twoCards.card1 : twoCards.card2),
                  cvv: e.target.value,
                },
              })
            }
          />
        </div>
        <div className="form-group mb-2 col-md-12">
          <p className="form-label m-0">Parcelamento *</p>
          <select
            name="installment"
            autoComplete="off"
            id="exampleForm.SelectParcelamento"
            className="form-control"
            onChange={e =>
              setTwoCards({
                ...twoCards,
                [`card${index}`]: {
                  ...(index === 1 ? twoCards.card1 : twoCards.card2),
                  installments: Number(e.target.value),
                },
              })
            }
            disabled={!item.value || item.value === 0 || loadingInstallments}
          >
            <option value="">Selecione o parcelamento</option>
            {(item.customInstallments || formPayment.installments)
              .filter(e => {
                const v =
                  index === 1 ? twoCards.card1.value : twoCards.card2.value;
                if (Math.floor(v / e.installment) > 0) {
                  return e;
                }
                return false;
              })
              .map(e => {
                let label = '';
                if (e.installment_value) {
                  label += formatCurrency(String(e.installment_value));
                  if (e.has_fee) {
                    label += ` (com juros)`;
                  } else {
                    label += ' (sem juros)';
                  }
                } else {
                  label += `${formatCurrency(
                    String(
                      Math.floor(
                        index === 1
                          ? twoCards.card1.value / e.installment
                          : twoCards.card2.value / e.installment,
                      ),
                    ),
                  )} (sem juros)`;
                }
                return (
                  <option key={e.installment} value={e.installment}>
                    {e.installment}x de {label}
                  </option>
                );
              })}
          </select>
        </div>
      </div>
    );
  };

  async function submitPaymentBoleto() {
    let gResponse = '';
    try {
      gResponse = await reRef?.current?.executeAsync();
      reRef?.current?.reset();
      setErrorMessage('');

      if (!gResponse) {
        setErrorMessage('Erro, atualize a tela e tente novamente');
        window.location.href = '#error__message';
        return;
      }
    } catch (err) {
      setErrorMessage(
        `Erro, atualize a tela e tente novamente. ${(err as any)?.message}`,
      );
      window.location.href = '#error__message';
      return;
    }
    setLoading(true);
    try {
      await api.post(
        `v2/orders/payments/boleto`,
        { order_id: order.id, gResponse },
        headersTokens,
      );
      try {
        TagManager.dataLayer({
          dataLayerName: 'PageDataLayer',
          dataLayer: {
            ecommerce: null,
          },
        });
        TagManager.dataLayer({
          dataLayerName: 'PageDataLayer',
          dataLayer: {
            event: 'purchase',
            ecommerce: {
              currency: 'BRL',
              total_items: order.orderProducts
                ?.map(o => o.qtd)
                .reduce((a, b) => a + b, 0),
              email: order.customer ? order.customer.email : null,
              purchase: {
                actionField: {
                  id: String(order.id),
                  affiliation: 'Online Store',
                  revenue: parseFloat((order.total / 100).toFixed(2)),
                  shipping:
                    order.frete > 0
                      ? parseFloat((order.frete / 100).toFixed(2))
                      : 0,
                  coupon: order.code_coupon || '',
                  codvendedor: order.seller ? order.seller.code : '',
                },
              },
              items: order.orderProducts?.map(orderProduct => ({
                item_name: orderProduct.product.name,
                item_id: orderProduct.product.sku,
                price: parseFloat((orderProduct.price / 100).toFixed(2)),
                item_brand: orderProduct.product.brand.name,
                quantity: orderProduct.qtd,
                item_category:
                  orderProduct.product.categories &&
                  orderProduct.product.categories[0]
                    ? orderProduct.product.categories[0].name
                    : '',
                is_in_stock: 'in_stock',
                product_image: orderProduct?.product?.photos[0].path,
                product_url: productUrl(
                  orderProduct?.product?.sku,
                  orderProduct?.product?.name,
                ),
              })),
            },
          },
        });
      } catch (err) {
        //
      }
      history.push(`/detalhamento-do-pedido/${order.id}`);
    } catch (e: any) {
      if (e.response.data.length) {
        setErrorMessage(e.response.data[0].message);
      } else if (
        e.response.data.message ===
        'Houve mudança de prazo ou valor do frete de alguns produtos, selecione novamente a forma de entrega para revisão.'
      ) {
        window.alert(e.response.data.message);
        window.location.reload();
      } else {
        setErrorMessage(e.response.data.message);
      }
      window.location.href = '#error__message';
    } finally {
      setLoading(false);
    }
  }

  async function submitPaymentPix() {
    let gResponse = '';
    try {
      gResponse = await reRef?.current?.executeAsync();
      reRef?.current?.reset();
      setErrorMessage('');

      if (!gResponse) {
        setErrorMessage('Erro, atualize a tela e tente novamente');
        window.location.href = '#error__message';
        return;
      }
    } catch (err) {
      setErrorMessage(
        `Erro, atualize a tela e tente novamente. ${(err as any)?.message}`,
      );
      window.location.href = '#error__message';
      return;
    }
    setLoading(true);
    try {
      await api.post(
        `v2/orders/payments/pix`,
        { order_id: order.id, gResponse },
        headersTokens,
      );
      try {
        TagManager.dataLayer({
          dataLayerName: 'PageDataLayer',
          dataLayer: {
            ecommerce: null,
          },
        });
        TagManager.dataLayer({
          dataLayerName: 'PageDataLayer',
          dataLayer: {
            event: 'purchase',
            ecommerce: {
              currency: 'BRL',
              total_items: order.orderProducts
                ?.map(o => o.qtd)
                .reduce((a, b) => a + b, 0),
              email: order.customer ? order.customer.email : null,
              purchase: {
                actionField: {
                  id: String(order.id),
                  affiliation: 'Online Store',
                  revenue: parseFloat((order.total / 100).toFixed(2)),
                  shipping:
                    order.frete > 0
                      ? parseFloat((order.frete / 100).toFixed(2))
                      : 0,
                  coupon: order.code_coupon || '',
                  codvendedor: order.seller ? order.seller.code : '',
                },
              },
              items: order.orderProducts?.map(orderProduct => ({
                item_name: orderProduct.product.name,
                item_id: orderProduct.product.sku,
                price: parseFloat((orderProduct.price / 100).toFixed(2)),
                item_brand: orderProduct.product.brand.name,
                quantity: orderProduct.qtd,
                item_category:
                  orderProduct.product.categories &&
                  orderProduct.product.categories[0]
                    ? orderProduct.product.categories[0].name
                    : '',
                is_in_stock: 'in_stock',
                product_image: orderProduct?.product?.photos[0].path,
                product_url: productUrl(
                  orderProduct?.product?.sku,
                  orderProduct?.product?.name,
                ),
              })),
            },
          },
        });
      } catch (err) {
        //
      }
      history.push(`/detalhamento-do-pedido/${order.id}`);
    } catch (e: any) {
      if (e.response.data.birthdate === 'required') {
        setBirthDateModal(true);
        return;
      }

      if (e.response.data.length) {
        setErrorMessage(e.response.data[0].message);
      } else if (
        e.response.data.message ===
        'Houve mudança de prazo ou valor do frete de alguns produtos, selecione novamente a forma de entrega para revisão.'
      ) {
        window.alert(e.response.data.message);
        window.location.reload();
      } else {
        setErrorMessage(e.response.data.message);
      }
      window.location.href = '#error__message';
    } finally {
      setLoading(false);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function tokenMp(form: any) {
    return new Promise((resolve, reject) => {
      try {
        mercadopago?.createToken(form, (status: number, response: any) => {
          if (status === 200 || status === 201) {
            return resolve(response.id);
          }
          return resolve('');
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  async function submitPaymentCardCredit(): Promise<void> {
    let gResponse = '';
    try {
      gResponse = await reRef?.current?.executeAsync();
      reRef?.current?.reset();
      setErrorMessage('');

      if (!gResponse) {
        setErrorMessage('Erro, atualize a tela e tente novamente');
        window.location.href = '#error__message';
        return;
      }
    } catch (err) {
      setErrorMessage(
        `Erro, atualize a tela e tente novamente. ${(err as any)?.message}`,
      );
      window.location.href = '#error__message';
      return;
    }
    setLoading(true);
    try {
      await api.post(
        `v2/orders/payments/credit_card`,
        {
          order_id: order.id,
          cardholderName: creditCard.cardholderName,
          cardholderDocument: normalizeNumber(
            creditCard.cardholderDocument || '',
          ),
          number: creditCard.cardNumber,
          month: creditCard.cardExpirationMonth,
          year: creditCard.cardExpirationYear,
          cvv: creditCard.securityCode,
          installment: creditCard.installment,
          sessionId,
          gResponse,
        },
        headersTokens,
      );
      try {
        TagManager.dataLayer({
          dataLayerName: 'PageDataLayer',
          dataLayer: {
            ecommerce: null,
          },
        });
        TagManager.dataLayer({
          dataLayerName: 'PageDataLayer',
          dataLayer: {
            event: 'purchase',
            ecommerce: {
              currency: 'BRL',
              total_items: order.orderProducts
                ?.map(o => o.qtd)
                .reduce((a, b) => a + b, 0),
              email: order.customer ? order.customer.email : null,
              purchase: {
                actionField: {
                  id: String(order.id),
                  affiliation: 'Online Store',
                  revenue: parseFloat((order.total / 100).toFixed(2)),
                  shipping:
                    order.frete > 0
                      ? parseFloat((order.frete / 100).toFixed(2))
                      : 0,
                  coupon: order.code_coupon || '',
                  codvendedor: order.seller ? order.seller.code : '',
                },
              },
              items: order.orderProducts?.map(orderProduct => ({
                item_name: orderProduct.product.name,
                item_id: orderProduct.product.sku,
                price: parseFloat((orderProduct.price / 100).toFixed(2)),
                item_brand: orderProduct.product.brand.name,
                quantity: orderProduct.qtd,
                item_category:
                  orderProduct.product.categories &&
                  orderProduct.product.categories[0]
                    ? orderProduct.product.categories[0].name
                    : '',
                is_in_stock: 'in_stock',
                product_image: orderProduct?.product?.photos[0].path,
                product_url: productUrl(
                  orderProduct?.product?.sku,
                  orderProduct?.product?.name,
                ),
              })),
            },
          },
        });
      } catch (err) {
        //
      }
      history.push(`/detalhamento-do-pedido/${order.id}`);
    } catch (e: any) {
      if (e.response.data.birthdate === 'required') {
        setBirthDateModal(true);
        return;
      }

      if (e.response.data.length) {
        setErrorMessage(e.response.data[0].message);
      } else if (
        e.response.data.message ===
        'Houve mudança de prazo ou valor do frete de alguns produtos, selecione novamente a forma de entrega para revisão.'
      ) {
        window.alert(e.response.data.message);
        window.location.reload();
      } else {
        setErrorMessage(e.response.data.message);
      }
      window.location.href = '#error__message';
    } finally {
      setLoading(false);
    }
  }

  async function submitPaymentTwoCardCreditAuth(): Promise<void> {
    let gResponse = '';
    try {
      gResponse = await reRef?.current?.executeAsync();
      reRef?.current?.reset();
      setErrorMessage('');

      if (!gResponse) {
        setErrorMessage('Erro, atualize a tela e tente novamente');
        window.location.href = '#error__message';
        return;
      }
    } catch (err) {
      setErrorMessage(
        `Erro, atualize a tela e tente novamente. ${(err as any)?.message}`,
      );
      window.location.href = '#error__message';
      return;
    }
    setLoading(true);
    try {
      const card1 = {
        id: twoCards.card1.id,
        cardholderName: twoCards.card1.hold_name,
        holderDocument: normalizeNumber(twoCards.card1.hold_document || ''),
        installments: twoCards.card1.installments,
        number: twoCards.card1.number,
        value: twoCards.card1.value,
        month: twoCards.card1.expire_month,
        year: twoCards.card1.expire_year,
        cvv: twoCards.card1.cvv,
      };

      const card2 = {
        id: twoCards.card2.id,
        cardholderName: twoCards.card2.hold_name,
        holderDocument: normalizeNumber(twoCards.card2.hold_document || ''),
        installments: twoCards.card2.installments,
        number: twoCards.card2.number,
        value: twoCards.card2.value,
        month: twoCards.card2.expire_month,
        year: twoCards.card2.expire_year,
        cvv: twoCards.card2.cvv,
      };

      await api.post(
        `v2/orders/payments/two_cards`,
        {
          order_id: order.id,
          card1,
          card2,
          sessionId,
          installment,
        },
        headersTokens,
      );
      try {
        TagManager.dataLayer({
          dataLayerName: 'PageDataLayer',
          dataLayer: {
            ecommerce: null,
          },
        });
        TagManager.dataLayer({
          dataLayerName: 'PageDataLayer',
          dataLayer: {
            event: 'purchase',
            ecommerce: {
              currency: 'BRL',
              total_items: order.orderProducts
                ?.map(o => o.qtd)
                .reduce((a, b) => a + b, 0),
              email: order.customer ? order.customer.email : null,
              purchase: {
                actionField: {
                  id: String(order.id),
                  affiliation: 'Online Store',
                  revenue: parseFloat((order.total / 100).toFixed(2)),
                  shipping:
                    order.frete > 0
                      ? parseFloat((order.frete / 100).toFixed(2))
                      : 0,
                  coupon: order.code_coupon || '',
                  codvendedor: order.seller ? order.seller.code : '',
                },
              },
              items: order.orderProducts?.map(orderProduct => ({
                item_name: orderProduct.product.name,
                item_id: orderProduct.product.sku,
                price: parseFloat((orderProduct.price / 100).toFixed(2)),
                item_brand: orderProduct.product.brand.name,
                quantity: orderProduct.qtd,
                item_category:
                  orderProduct.product.categories &&
                  orderProduct.product.categories[0]
                    ? orderProduct.product.categories[0].name
                    : '',
                is_in_stock: 'in_stock',
                product_image: orderProduct?.product?.photos[0].path,
                product_url: productUrl(
                  orderProduct?.product?.sku,
                  orderProduct?.product?.name,
                ),
              })),
            },
          },
        });
      } catch (err) {
        //
      }
      history.push(`/detalhamento-do-pedido/${order.id}`);
    } catch (e: any) {
      if (e.response.data.birthdate === 'required') {
        setBirthDateModal(true);
        return;
      }

      if (e.response.data.length) {
        setErrorMessage(e.response.data[0].message);
      } else if (
        e.response.data.message ===
        'Houve mudança de prazo ou valor do frete de alguns produtos, selecione novamente a forma de entrega para revisão.'
      ) {
        window.alert(e.response.data.message);
        window.location.reload();
      } else {
        setErrorMessage(e.response.data.message);
      }
      window.location.href = '#error__message';
    } finally {
      setLoading(false);
    }
  }

  async function submitPaymentCardCreditAuth(): Promise<void> {
    let gResponse = '';
    try {
      gResponse = await reRef?.current?.executeAsync();
      reRef?.current?.reset();
      setErrorMessage('');

      if (!gResponse) {
        setErrorMessage('Erro, atualize a tela e tente novamente');
        window.location.href = '#error__message';
        return;
      }
    } catch (err) {
      setErrorMessage(
        `Erro, atualize a tela e tente novamente. ${(err as any)?.message}`,
      );
      window.location.href = '#error__message';
      return;
    }
    setErrorMessage('');
    if (!creditCard.securityCode) {
      setErrorMessage('CVV do cartão é obrigatório.');
      return;
    }
    setLoading(true);
    try {
      await api.post(
        `v2/orders/payments/credit_card`,
        {
          order_id: order.id,
          customer_card_id: card.id,
          sessionId,
          installment,
          cvv: creditCard.securityCode,
          gResponse,
        },
        headersTokens,
      );
      try {
        TagManager.dataLayer({
          dataLayerName: 'PageDataLayer',
          dataLayer: {
            ecommerce: null,
          },
        });
        TagManager.dataLayer({
          dataLayerName: 'PageDataLayer',
          dataLayer: {
            event: 'purchase',
            ecommerce: {
              currency: 'BRL',
              total_items: order.orderProducts
                ?.map(o => o.qtd)
                .reduce((a, b) => a + b, 0),
              email: order.customer ? order.customer.email : null,
              purchase: {
                actionField: {
                  id: String(order.id),
                  affiliation: 'Online Store',
                  revenue: parseFloat((order.total / 100).toFixed(2)),
                  shipping:
                    order.frete > 0
                      ? parseFloat((order.frete / 100).toFixed(2))
                      : 0,
                  coupon: order.code_coupon || '',
                  codvendedor: order.seller ? order.seller.code : '',
                },
              },
              items: order.orderProducts?.map(orderProduct => ({
                item_name: orderProduct.product.name,
                item_id: orderProduct.product.sku,
                price: parseFloat((orderProduct.price / 100).toFixed(2)),
                item_brand: orderProduct.product.brand.name,
                quantity: orderProduct.qtd,
                item_category:
                  orderProduct.product.categories &&
                  orderProduct.product.categories[0]
                    ? orderProduct.product.categories[0].name
                    : '',
                is_in_stock: 'in_stock',
                product_image: orderProduct?.product?.photos[0].path,
                product_url: productUrl(
                  orderProduct?.product?.sku,
                  orderProduct?.product?.name,
                ),
              })),
            },
          },
        });
      } catch (err) {
        //
      }
      history.push(`/detalhamento-do-pedido/${order.id}`);
    } catch (e: any) {
      if (e.response.data.birthdate === 'required') {
        setBirthDateModal(true);
        return;
      }

      if (e.response.data.length) {
        setErrorMessage(e.response.data[0].message);
      } else if (
        e.response.data.message ===
        'Houve mudança de prazo ou valor do frete de alguns produtos, selecione novamente a forma de entrega para revisão.'
      ) {
        window.alert(e.response.data.message);
        window.location.reload();
      } else {
        setErrorMessage(e.response.data.message);
      }
      window.location.href = '#error__message';
    } finally {
      setLoading(false);
    }
  }

  async function submitPaymentCardDebit() {
    const gResponse = await reRef?.current?.executeAsync();
    reRef?.current?.reset();
    setErrorMessage('');

    if (!gResponse) {
      setErrorMessage('Erro, atualize a tela e tente novamente');
      window.location.href = '#error__message';
      return false;
    }
    setLoading(true);
    try {
      await api.post(
        `v2/orders/payments/debit_card`,
        {
          order_id: order.id,
          cardholderName: debitCard.cardHolder,
          number: debitCard.number,
          month: debitCard.month,
          year: debitCard.year,
          cvv: debitCard.cvv,
          sessionId,
          gResponse,
        },
        headersTokens,
      );
      try {
        TagManager.dataLayer({
          dataLayerName: 'PageDataLayer',
          dataLayer: {
            ecommerce: null,
          },
        });
        TagManager.dataLayer({
          dataLayerName: 'PageDataLayer',
          dataLayer: {
            event: 'purchase',
            ecommerce: {
              currency: 'BRL',
              total_items: order.orderProducts
                ?.map(o => o.qtd)
                .reduce((a, b) => a + b, 0),
              email: order.customer ? order.customer.email : null,
              purchase: {
                actionField: {
                  id: String(order.id),
                  affiliation: 'Online Store',
                  revenue: parseFloat((order.total / 100).toFixed(2)),
                  shipping:
                    order.frete > 0
                      ? parseFloat((order.frete / 100).toFixed(2))
                      : 0,
                  coupon: order.code_coupon || '',
                  codvendedor: order.seller ? order.seller.code : '',
                },
              },
              items: order.orderProducts?.map(orderProduct => ({
                item_name: orderProduct.product.name,
                item_id: orderProduct.product.sku,
                price: parseFloat((orderProduct.price / 100).toFixed(2)),
                item_brand: orderProduct.product.brand.name,
                quantity: orderProduct.qtd,
                item_category:
                  orderProduct.product.categories &&
                  orderProduct.product.categories[0]
                    ? orderProduct.product.categories[0].name
                    : '',
                is_in_stock: 'in_stock',
                product_image: orderProduct?.product?.photos[0].path,
                product_url: productUrl(
                  orderProduct?.product?.sku,
                  orderProduct?.product?.name,
                ),
              })),
            },
          },
        });
      } catch (err) {
        //
      }
      history.push(`/detalhamento-do-pedido/${order.id}`);
    } catch (e: any) {
      if (e.response.data.birthdate === 'required') {
        setBirthDateModal(true);
      }
      if (e.response.data.length) {
        setErrorMessage(e.response.data[0].message);
      } else {
        setErrorMessage(e.response.data.message);
      }
      window.location.href = '#error__message';
    }
    return setLoading(false);
  }

  async function submitPaymentCardDebitAuth() {
    const gResponse = await reRef?.current?.executeAsync();
    reRef?.current?.reset();
    setErrorMessage('');

    if (!gResponse) {
      setErrorMessage('Erro, atualize a tela e tente novamente');
      window.location.href = '#error__message';
      return false;
    }
    setLoading(true);
    try {
      await api.post(
        `v2/orders/payments/debit_card`,
        {
          order_id: order.id,
          customer_card_id: card.id,
          sessionId,
          gResponse,
        },
        headersTokens,
      );
      try {
        TagManager.dataLayer({
          dataLayerName: 'PageDataLayer',
          dataLayer: {
            ecommerce: null,
          },
        });
        TagManager.dataLayer({
          dataLayerName: 'PageDataLayer',
          dataLayer: {
            event: 'purchase',
            ecommerce: {
              currency: 'BRL',
              total_items: order.orderProducts
                ?.map(o => o.qtd)
                .reduce((a, b) => a + b, 0),
              email: order.customer ? order.customer.email : null,
              purchase: {
                actionField: {
                  id: String(order.id),
                  affiliation: 'Online Store',
                  revenue: parseFloat((order.total / 100).toFixed(2)),
                  shipping:
                    order.frete > 0
                      ? parseFloat((order.frete / 100).toFixed(2))
                      : 0,
                  coupon: order.code_coupon || '',
                  codvendedor: order.seller ? order.seller.code : '',
                },
              },
              items: order.orderProducts?.map(orderProduct => ({
                item_name: orderProduct.product.name,
                item_id: orderProduct.product.sku,
                price: parseFloat((orderProduct.price / 100).toFixed(2)),
                item_brand: orderProduct.product.brand.name,
                quantity: orderProduct.qtd,
                item_category:
                  orderProduct.product.categories &&
                  orderProduct.product.categories[0]
                    ? orderProduct.product.categories[0].name
                    : '',
                is_in_stock: 'in_stock',
                product_image: orderProduct?.product?.photos[0].path,
                product_url: productUrl(
                  orderProduct?.product?.sku,
                  orderProduct?.product?.name,
                ),
              })),
            },
          },
        });
      } catch (err) {
        //
      }
      history.push(`/detalhamento-do-pedido/${order.id}`);
    } catch (e: any) {
      if (e.response.data.birthdate === 'required') {
        setBirthDateModal(true);
      }
      if (e.response.data.length) {
        setErrorMessage(e.response.data[0].message);
      } else {
        setErrorMessage(e.response.data.message);
      }
      window.location.href = '#error__message';
    }
    return setLoading(false);
  }

  async function handleSubmitBirthDate(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    setLoading(true);

    setErrorMessage('');

    if (!order.customer) {
      setLoading(false);
      return;
    }

    const splitDate = String(birthdate).split('/');

    if (splitDate.length !== 3) {
      setErrorMessage('Data de nascimento inválida');
      setLoading(false);
    }

    try {
      await api.put(
        'customer/me/birthdate',
        { birthdate: `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}` },
        headersTokens,
      );

      setBirthDateModal(false);
    } catch (erro: any) {
      if (erro.response.data.birthdate === 'required') {
        setBirthDateModal(true);
        return;
      }

      if (erro?.response?.data.length) {
        setErrorMessage(erro?.response?.data[0].message);
      } else {
        setErrorMessage(erro?.response?.data.message);
      }
    }
    setLoading(false);
  }

  const discountBadge = (type: string) => {
    if (
      order.orderProducts?.find(
        p =>
          (p.product.price_discount && (type === 'boleto' || type === 'pix')) ||
          (p.product.price_in_full && type !== 'two_cards'),
      ) &&
      !order.code_coupon
    ) {
      return (
        <Badge variant="success" pill className="bg-success">
          Desconto à vista
        </Badge>
      );
    }
    return null;
  };

  const textFare = useMemo(() => {
    if (Array.isArray(formPayment.installments)) {
      if (creditCard.installment) {
        const installmentItem = formPayment.installments.find(
          i => Number(i.installment) === Number(creditCard.installment),
        );
        if (
          installmentItem &&
          installmentItem.has_fee &&
          installmentItem.fee_percentage
        ) {
          return (
            <span className="d-block mt-2">
              Juros médio de{' '}
              <strong>
                {parseFloat(installmentItem.fee_percentage).toFixed(2)}%
              </strong>{' '}
              a.m. sobre o subtotal.
              <br />
              Valor Total com Juros:{' '}
              <strong>
                R${' '}
                {normalizeCurrency(
                  String(
                    Math.round(
                      installmentItem.value * installmentItem.installment,
                    ),
                  ),
                )}
              </strong>
            </span>
          );
        }
      }
      if (installment) {
        const installmentItem = formPayment.installments.find(
          i => Number(i.installment) === Number(installment),
        );
        if (
          installmentItem &&
          installmentItem.has_fee &&
          installmentItem.fee_percentage
        ) {
          return (
            <span className="d-block mt-2">
              Juros médio de{' '}
              <strong>
                {parseFloat(installmentItem.fee_percentage).toFixed(2)}%
              </strong>{' '}
              a.m. sobre o subtotal.
              <br />
              Valor Total com Juros:{' '}
              <strong>
                R${' '}
                {normalizeCurrency(
                  String(
                    Math.round(
                      installmentItem.value * installmentItem.installment,
                    ),
                  ),
                )}
              </strong>
            </span>
          );
        }
      }
    }
    return null;
  }, [installment, creditCard.installment, formPayment.installments]);

  const haveCard = cards.map(e => e.card).join(', ') !== '';

  const addPaymentInfo = useCallback(
    (paymentMethod: string) => {
      TagManager.dataLayer({
        dataLayerName: 'PageDataLayer',
        dataLayer: {
          event: 'add_payment_info',
          ecommerce: {
            currency: 'BRL',
            value: parseFloat((order?.total / 100).toFixed(2)),
            payment_type: paymentMethod,
            items: order.orderProducts?.map(orderProducts => ({
              item_id: orderProducts?.product?.sku,
              item_name: orderProducts?.product?.name,
              item_brand: orderProducts?.product?.brand?.name,
              item_category: orderProducts?.product?.categories[0]?.name,
              price: parseFloat(
                (orderProducts?.product?.price_end / 100).toFixed(2),
              ),
              quantity: orderProducts?.qtd,
              is_in_stock: 'in_stock',
              product_image: orderProducts?.product?.photos[0].path,
              product_url: productUrl(
                orderProducts?.product?.sku,
                orderProducts?.product?.name,
              ),
            })),
          },
        },
      });
    },
    [order.orderProducts, order.total],
  );

  return (
    <div>
      {loading && <LoadingScreen />}
      {!order.loading ? (
        <>
          <Modal className="modal-no-top" show={birthDateModal} centered>
            <Modal.Body>
              <div className="p-3">
                <p className="alert alert-warning">
                  Para finalizar a compra, é necessário informar sua data de
                  nascimento. <br />
                  <br />
                  <span style={{ fontSize: '12px' }} className="fst-italic">
                    * Em caso de garantia estendida, a idade mínima é de 18
                    anos.
                  </span>
                </p>
                <form onSubmit={handleSubmitBirthDate}>
                  {errorMessage !== '' && (
                    <p className="alert alert-danger">{errorMessage}</p>
                  )}
                  <Row>
                    <Col lg={12}>
                      <Form.Group>
                        <Form.Label>Data de nascimento</Form.Label>
                        <Form.Control
                          name="birthdate"
                          value={birthdate}
                          onChange={e => {
                            setBirthdate(normalizeDate(e.target.value));
                          }}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <button
                      className="btn btn-imperio pb-2 pl-4 pr-4 pt-2 btn-block"
                      type="submit"
                      disabled={loading}
                    >
                      Salvar
                    </button>
                    <button
                      className="btn btn-ligth ml-2 pb-2 pl-4 pr-4 pt-2 btn-block"
                      type="button"
                      onClick={() => setBirthDateModal(false)}
                    >
                      Cancelar
                    </button>
                  </div>
                </form>
              </div>
            </Modal.Body>
          </Modal>
          <div className="row">
            <div className="col-md-5 mt-3">
              <div className="flex-column">
                {order.formPayments &&
                  order.formPayments
                    .filter(e => e.type !== 'imperio_card')
                    .map(e => {
                      return (
                        <button
                          className={`d-flex align-items-center mt-2 w-100 ${e.type}`}
                          onClick={() => {
                            setErrorMessage('');
                            setFormSelect(e.type === formSelect ? '' : e.type);
                            setFormPayment(
                              e.type === formPayment.type
                                ? { type: '' as any, installments: [] }
                                : e,
                            );
                            addPaymentInfo(e.type);
                          }}
                          style={{
                            border: '1px solid #0070ae',
                            color: e.type === formSelect ? '#fff' : '#0070ae',
                            background:
                              e.type === formSelect ? '#0070ae' : undefined,
                            padding: '0.5rem 1rem',
                            borderRadius: '4px',
                          }}
                          type="button"
                          key={e.type}
                        >
                          <i
                            className={`fas ${switchFormPaymentIcon(
                              String(e.type),
                            )} mr-2`}
                          />
                          <div
                            className="d-flex align-items-center flex-wrap"
                            style={{ marginLeft: '0.6rem' }}
                          >
                            <p
                              className="mb-0"
                              style={{ marginRight: '0.3rem' }}
                            >
                              {switchFormPayment(String(e.type))}
                            </p>
                            {discountBadge(e.type)}
                          </div>
                        </button>
                      );
                    })}
              </div>
            </div>
            <div className="col-md-7 mt-3">
              {!formPayment.type && (
                <div
                  className="p-5 bg-white d-flex justify-content-center align-items-center shadow-sm"
                  style={{ height: '100%' }}
                >
                  <h4 className="text-muted text-center">
                    Escolha uma forma de pagamento
                  </h4>
                </div>
              )}
              {formPayment.type === 'debit_card' && (
                <div className="row">
                  <div className="col">
                    {order.mask || formNewCard ? (
                      <form autoComplete="off">
                        {errorMessage !== '' && (
                          <p
                            className="alert alert-danger mb-3"
                            id="error__message"
                          >
                            {errorMessage}
                          </p>
                        )}
                        <div className="form-group mb-2 col-md-5">
                          <p className="form-label m-0">Número do cartão *</p>
                          <input
                            name="number"
                            placeholder="0000 0000 0000 0000"
                            className="form-control"
                            onChange={e =>
                              setDebitCard({
                                ...debitCard,
                                number: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="form-group mb-2 col-md-4">
                          <p className="form-label m-0">Data de vencimento *</p>
                          <div className="d-flex">
                            <select
                              name="month"
                              onChange={e =>
                                setDebitCard({
                                  ...debitCard,
                                  month: e.target.value,
                                })
                              }
                              className="mr-1 form-control"
                            >
                              <option value="">MM</option>
                              <option value="01">01</option>
                              <option value="02">02</option>
                              <option value="03">03</option>
                              <option value="04">04</option>
                              <option value="05">05</option>
                              <option value="06">06</option>
                              <option value="07">07</option>
                              <option value="08">08</option>
                              <option value="09">09</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                              <option value="12">12</option>
                            </select>
                            <select
                              name="year"
                              onChange={e =>
                                setDebitCard({
                                  ...debitCard,
                                  year: e.target.value,
                                })
                              }
                              className="ml-1 form-control"
                            >
                              <option value="">AAAA</option>
                              {yearsOptions}
                            </select>
                          </div>
                        </div>
                        <div className="form-group mb-2 col-md-3">
                          <p className="form-label m-0">CVV *</p>
                          <input
                            name="cvv"
                            placeholder="XXX"
                            className="form-control"
                            onChange={e =>
                              setDebitCard({
                                ...debitCard,
                                cvv: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="form-group mb-2 col-md-6">
                          <p className="form-label m-0">Nome do titular *</p>
                          <input
                            name="cardHolder"
                            placeholder="Digite nome do titular"
                            className="form-control"
                            onChange={e =>
                              setDebitCard({
                                ...debitCard,
                                cardHolder: e.target.value,
                              })
                            }
                          />
                          <small
                            className="d-block"
                            style={{ fontSize: '12px' }}
                          >
                            Digite exatamente como está no seu cartão
                          </small>
                        </div>
                        <div className="text-right">
                          <button
                            type="button"
                            disabled={loading}
                            onClick={() => submitPaymentCardDebit()}
                            className="btn btn-buy"
                          >
                            Finalizar Pedido
                          </button>
                        </div>
                      </form>
                    ) : (
                      <div>
                        {errorMessage !== '' && (
                          <p className="alert alert-danger mb-3">
                            {errorMessage}
                          </p>
                        )}
                        {cards.map(e => {
                          return (
                            <div className="addressList" key={e.id}>
                              <button
                                type="button"
                                className={e.id === card.id ? 'active' : ''}
                                onClick={() => setCard(e)}
                              >
                                .
                              </button>
                              <p className="d-flex align-items-center">
                                {e.is_imperio_card ? (
                                  <img
                                    src="https://www.lojasimperio.com.br/images/favicon/favicon.ico"
                                    alt="Cartão Lojas Império"
                                    style={{ width: '18px' }}
                                  />
                                ) : null}{' '}
                                {e.card}
                              </p>
                            </div>
                          );
                        })}
                        <button
                          type="button"
                          className="btn btn-imperio btn-small"
                          onClick={() => setFormNewCard(true)}
                        >
                          Digitar novo cartão de crédito
                        </button>
                        <button
                          type="button"
                          disabled={loading}
                          onClick={() => submitPaymentCardDebitAuth()}
                          className="btn btn-buy"
                        >
                          Finalizar Pedido
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {formPayment.type === 'credit_card' && (
                <div
                  className="p-3 bg-white shadow-sm radius-4"
                  style={{ borderRadius: '0.4rem' }}
                >
                  <div>
                    {order.mask || formNewCard || !haveCard ? (
                      <form className="row" ref={formCardCredit}>
                        {errorMessage !== '' && (
                          <p
                            className="alert alert-danger mb-3"
                            id="error__message"
                          >
                            {errorMessage}
                          </p>
                        )}
                        <input
                          type="hidden"
                          data-checkout="docType"
                          id="docType"
                          name="docType"
                          value="CPF"
                        />
                        <input
                          type="hidden"
                          data-checkout="docNumber"
                          id="docNumber"
                          name="docNumber"
                          value={order.cpf}
                        />
                        <div className="form-group mb-2 col-md-12">
                          <p className="form-label m-0">Número do cartão *</p>
                          <input
                            data-checkout="cardNumber"
                            id="cardNumber"
                            name="cardNumber"
                            placeholder="0000 0000 0000 0000"
                            className="form-control"
                            value={creditCard.cardNumber || ''}
                            onChange={e =>
                              setCreditCard({
                                ...creditCard,
                                cardNumber: String(
                                  normalizeNumber(e.target.value),
                                ).slice(0, 16),
                                installment: '',
                              })
                            }
                          />
                        </div>
                        <div className="form-group mb-2 col-md-8">
                          <p className="form-label m-0">Data de vencimento *</p>
                          <div className="d-flex">
                            <select
                              data-checkout="cardExpirationMonth"
                              id="cardExpirationMonth"
                              name="cardExpirationMonth"
                              value={creditCard.cardExpirationMonth || ''}
                              onChange={e =>
                                setCreditCard({
                                  ...creditCard,
                                  cardExpirationMonth: e.target.value,
                                })
                              }
                              className="mr-1 form-control"
                            >
                              <option value="">MM</option>
                              <option value="01">01</option>
                              <option value="02">02</option>
                              <option value="03">03</option>
                              <option value="04">04</option>
                              <option value="05">05</option>
                              <option value="06">06</option>
                              <option value="07">07</option>
                              <option value="08">08</option>
                              <option value="09">09</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                              <option value="12">12</option>
                            </select>
                            <select
                              data-checkout="cardExpirationYear"
                              id="cardExpirationYear"
                              name="cardExpirationYear"
                              value={creditCard.cardExpirationYear || ''}
                              onChange={e =>
                                setCreditCard({
                                  ...creditCard,
                                  cardExpirationYear: e.target.value,
                                })
                              }
                              className="ml-1 form-control"
                            >
                              <option value="">AAAA</option>
                              {yearsOptions}
                            </select>
                          </div>
                        </div>
                        <div className="form-group mb-2 col-md-4">
                          <p className="form-label m-0">CVV *</p>
                          <input
                            data-checkout="securityCode"
                            id="securityCode"
                            name="securityCode"
                            placeholder="XXX"
                            className="form-control"
                            value={creditCard.securityCode || ''}
                            onChange={e =>
                              setCreditCard({
                                ...creditCard,
                                securityCode: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="form-group mb-2 col-md-12">
                          <p className="form-label m-0">Nome do titular *</p>
                          <input
                            data-checkout="cardholderName"
                            id="cardholderName"
                            name="cardholderName"
                            placeholder="Digite nome do titular"
                            className="form-control"
                            value={creditCard.cardholderName || ''}
                            onChange={e =>
                              setCreditCard({
                                ...creditCard,
                                cardholderName: String(e.target.value).slice(
                                  0,
                                  30,
                                ),
                              })
                            }
                          />
                          <small
                            className="d-block"
                            style={{ fontSize: '12px' }}
                          >
                            Digite exatamente como está no seu cartão
                          </small>
                        </div>
                        <div className="form-group mb-2 col-md-12">
                          <p className="form-label m-0">
                            CPF do titular do cartão *
                          </p>
                          <input
                            data-checkout="cardholderDocument"
                            id="cardholderDocument"
                            name="cardholderDocument"
                            placeholder="Digite cpf do titular do cartão"
                            className="form-control"
                            value={creditCard.cardholderDocument || ''}
                            onChange={e =>
                              setCreditCard({
                                ...creditCard,
                                cardholderDocument: normalizeCpf(
                                  String(e.target.value),
                                ),
                              })
                            }
                          />
                        </div>
                        <div className="form-group mb-2 col-md-12">
                          <p className="form-label m-0">Parcelamento *</p>
                          <select
                            name="installment"
                            id="exampleForm.SelectParcelamento"
                            className="form-control"
                            value={creditCard.installment || ''}
                            onChange={e => {
                              const isImperioCard =
                                creditCard.cardNumber &&
                                creditCard.cardNumber.slice(0, 6) === '404002';
                              setCreditCard({
                                ...creditCard,
                                installment: e.target.value,
                              });
                              const installmentItem = (
                                isImperioCard
                                  ? imperioCardInstallments
                                  : formPayment.installments
                              ).find(
                                i =>
                                  Number(i.installment) ===
                                  Number(e.target.value),
                              );
                              if (installmentItem && installmentItem.has_fee) {
                                setTotal(
                                  Math.round(
                                    installmentItem.value *
                                      installmentItem.installment,
                                  ),
                                );
                              } else {
                                setTotal(order.total);
                              }
                            }}
                          >
                            <option value="">Selecione o parcelamento</option>
                            {(creditCard.cardNumber &&
                            creditCard.cardNumber.slice(0, 6) === '404002'
                              ? imperioCardInstallments
                              : formPayment.installments
                            ).map(e => {
                              return (
                                <option
                                  key={e.installment}
                                  value={e.installment}
                                >
                                  {e.installment}x de{' '}
                                  {formatCurrency(String(e.value))} (
                                  {e.has_fee ? 'com juros' : 'sem juros'})
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        {textFare}
                        {hasLiveloPoints && installment
                          ? liveloTotalPointsBadge
                          : null}
                        <div className="text-right">
                          <button
                            type="button"
                            disabled={loading}
                            onClick={() => submitPaymentCardCredit()}
                            className="btn btn-buy"
                          >
                            Finalizar Pedido
                          </button>
                        </div>
                      </form>
                    ) : (
                      <div>
                        {errorMessage !== '' && (
                          <p className="alert alert-danger mb-3">
                            {errorMessage}
                          </p>
                        )}
                        {cards.map(e => {
                          return (
                            <button
                              type="button"
                              className="addressList w-100 text-left"
                              style={{ minWidth: 'unset' }}
                              key={e.id}
                              onClick={() => {
                                setCard(
                                  e.id === card.id
                                    ? {
                                        brand: '',
                                        card: '',
                                        id: 0,
                                        is_imperio_card: false,
                                      }
                                    : e,
                                );
                                setInstallment(0);
                              }}
                            >
                              <button
                                type="button"
                                className={e.id === card.id ? 'active' : ''}
                              >
                                .
                              </button>
                              <span className="d-flex align-items-center text-left">
                                {e.is_imperio_card ? (
                                  <img
                                    src="https://www.lojasimperio.com.br/images/favicon/favicon.ico"
                                    alt="Cartão Lojas Império"
                                    style={{ width: '18px' }}
                                  />
                                ) : null}{' '}
                                {e.card}
                              </span>
                            </button>
                          );
                        })}
                        <button
                          type="button"
                          className="btn btn-imperio btn-small"
                          onClick={() => setFormNewCard(true)}
                        >
                          Digitar novo cartão de crédito
                        </button>
                        <div className="form-group mb-2 mt-4 col-md-4">
                          <p className="form-label m-0">CVV *</p>
                          <input
                            data-checkout="securityCode"
                            id="securityCode"
                            name="securityCode"
                            placeholder="XXX"
                            className="form-control"
                            value={creditCard.securityCode || ''}
                            onChange={e =>
                              setCreditCard({
                                ...creditCard,
                                securityCode: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="form-group mb-2 col-md-8 mt-2">
                          <p className="form-label m-0">PARCELAMENTO*</p>
                          <select
                            name="installment"
                            id="exampleForm.SelectParcelamento"
                            className="form-control"
                            value={installment || ''}
                            onChange={e => {
                              setInstallment(Number(e.target.value));
                              const installmentItem =
                                formPayment.installments.find(
                                  i =>
                                    Number(i.installment) ===
                                    Number(e.target.value),
                                );
                              if (installmentItem && installmentItem.has_fee) {
                                setTotal(
                                  Math.round(
                                    installmentItem.value *
                                      installmentItem.installment,
                                  ),
                                );
                              } else {
                                setTotal(order.total);
                              }
                            }}
                          >
                            <option value="">Selecione o parcelamento</option>
                            {(card.is_imperio_card
                              ? imperioCardInstallments
                              : formPayment.installments
                            ).map(e => {
                              return (
                                <option
                                  key={e.installment}
                                  value={e.installment}
                                >
                                  {e.installment}x de{' '}
                                  {e.installment === 1 && order.code_coupon
                                    ? formatCurrency(String(order.total))
                                    : formatCurrency(String(e.value))}{' '}
                                  ({e.has_fee ? 'com juros' : 'sem juros'})
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        {textFare}
                        {hasLiveloPoints && installment
                          ? liveloTotalPointsBadge
                          : null}
                        <button
                          type="button"
                          disabled={loading}
                          onClick={() => submitPaymentCardCreditAuth()}
                          className="btn btn-buy"
                        >
                          Finalizar Pedido
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {formPayment.type === 'boleto' && (
                <div className="mb-5 p-4 shadow-sm bg-white">
                  {errorMessage !== '' && (
                    <p className="alert alert-danger mb-3">{errorMessage}</p>
                  )}
                  <p>
                    Valor total com desconto:{' '}
                    <strong>{formatCurrency(String(totalDiscount))}</strong>
                  </p>
                  {hasLiveloPoints ? liveloTotalPointsBadge : null}
                  <button
                    type="button"
                    onClick={() => submitPaymentBoleto()}
                    className="btn btn-buy mt-4"
                    disabled={loading}
                  >
                    Gerar Boleto
                  </button>
                </div>
              )}
              {formPayment.type === 'pix' && (
                <div className="mb-5 p-4 shadow-sm bg-white">
                  {errorMessage !== '' && (
                    <p className="alert alert-danger mb-3">{errorMessage}</p>
                  )}
                  <p>
                    Valor total com desconto:{' '}
                    <strong>{formatCurrency(String(totalDiscount))}</strong>
                  </p>
                  <p>
                    <strong>Atenção:</strong> O código PIX estará disponível
                    para pagamento por <strong>20 minutos</strong>. Após este
                    tempo o código expirará.
                  </p>
                  {hasLiveloPoints ? liveloTotalPointsBadge : null}
                  <button
                    type="button"
                    onClick={() => submitPaymentPix()}
                    className="btn btn-buy mt-4"
                    disabled={loading}
                  >
                    Pagar com PIX
                  </button>
                </div>
              )}
              {formPayment.type === 'two_cards' && (
                <form className="mb-5 mt-0">
                  <div>
                    {errorMessage !== '' && (
                      <p className="alert alert-danger mb-3">{errorMessage}</p>
                    )}
                  </div>
                  <div>
                    <div>{renderTwoCards(1)}</div>
                    <div>{renderTwoCards(2)}</div>
                  </div>
                  {hasLiveloPoints ? (
                    <div className="bg-white p-2 mb-4">
                      {liveloTotalPointsBadge}
                    </div>
                  ) : null}
                  <div className="d-flex justify-content-center">
                    <button
                      type="button"
                      disabled={loading}
                      onClick={() => submitPaymentTwoCardCreditAuth()}
                      className="btn btn-buy mt-0"
                    >
                      Finalizar Pedido
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
          <ReCAPTCHA
            sitekey="6Lf8NtUaAAAAAEJyuqWiJAo9K4-y_2KqBfP6A_VM"
            size="invisible"
            ref={reRef}
          />
        </>
      ) : (
        <p className="textLoading" />
      )}
    </div>
  );
};

export default Payment;
